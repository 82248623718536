<template>
    <!-- <HeroPage v-if="pageData" :data="pageData" /> -->

    <!-- <ContentGridFilterCollection :paging="paging" searchParams="" :collectionFilter="collectionFilter"
            contentType="journey" /> -->

    <section class="hero page no-bg type-undefined type-center">
        <div class="container">
            <div class="column-wrapper">
                <div class="column content">
                    <h1 class="block-title">My JOURNEYS</h1>
                    <span class="block-subtitle">Select your video’s and create your own journeys</span>
                </div>
            </div>
        </div>
    </section>

    <section class="section add-training-section">
        <div class="container">
            <div class="column-wrapper">
                <div class="column content">
                    <!-- <div class="text-wrapper" v-for="item in newsletterData" :key="item.id">
                        <h2 class="column-title" v-html="item.templateContentFields.displayTitle"></h2>
                    </div> -->

                    <button class="button" @click="toggleEditJourneyNamePopup">Add new journey</button>

                    <!-- <form v-if="showAddJourneyForm" @submit.prevent="submitForm">
                        <div v-if="showError" class="error-msg">
                            <p>{{ errorMsg }}</p>
                        </div>
                        <div v-if="showSucces" class="succes-msg">
                            <p>{{ succesMsg }}</p>
                        </div>

                        <div class="form-row">
                            <span class="field-wrapper text">
                                <label class="top-level" for="training-name-input">naam</label>
                                <div class="input-wrapper">
                                    <input type="text" id="training-name-input" name="trainingName" placeholder="naam"
                                        @keyup="changeInput()" v-model="trainingName" />
                                </div>
                            </span>
                        </div>


                        <button class="button submit">
                            Create journey
                        </button>
                    </form> -->

                </div>
            </div>
        </div>
    </section>

    <section class="section training-overview-section" v-if="trainingItems">
        <div class="container">
            <div class="column-wrapper">
                <div class="column collection">

                    <ul>
                        <li class="training-overview" v-for="training in trainingItems" :key="training.id">
                            <TrainingOverviewItem :data="training" @sortUp="sortOrderUp(training)"
                                @sortDown="sortOrderDown(training)" @deleteItem="deleteTraining(training.id)" />
                        </li>
                    </ul>

                </div>
            </div>
        </div>
    </section>

    <transition name="fade">
        <Popup :elemVisible="showEditJourneyNamePopup" :elemClass="'edit-journey-form'" @hidePopupEl="hidePopups()">
            <div class="column form-column edit-journey-form">
                <h2 class="column-title">Create your journey</h2>

                <form @submit.prevent="submitForm">
                    <div v-if="editJourneyShowError" class="error-msg">
                        <p>{{ editJourneyErrorMsg }}</p>
                    </div>
                    <div v-if="editJourneyShowSucces" class="succes-msg">
                        <p>{{ editJourneySuccesMsg }}</p>
                    </div>

                    <div class="form-row">
                        <span class="field-wrapper text">
                            <label class="top-level" for="training-name-input">Journey name</label>
                            <div class="input-wrapper">
                                <input type="text" id="training-name-input" name="trainingName" placeholder="name"
                                    @keyup="editJourneyChangeInput()" v-model="trainingName" />
                            </div>
                        </span>
                    </div>


                    <button class="button submit">
                        Next step
                    </button>
                    <button class="close-btn" @click="hidePopups"></button>
                </form>
            </div>

        </Popup>
    </transition>

</template>

<script>
import { fetchPageBySlug } from "@streampac.io/chef_sp_1";


import {
    createTraining,
    // fetchTrainingSingle,
    // updateTrainingSingle,
    deleteTrainingSingle,
    fetchTrainingList
} from "@streampac.io/chef_sp_1";



// import ContentGridFilterCollection from "@/components/ContentGridFilterCollection";

// import HeroPage from "@/components/HeroPage";

import Popup from "@/components/Popup";

import TrainingOverviewItem from "@/components/TrainingOverviewItem";

export default {
    name: "home",
    inject: ["isAuthenticated"],
    metaInfo() {
        return {
            title: "LEAD Hockey online",
        };
    },
    data() {
        return {
            pageData: null,

            showAddJourneyForm: false,
            trainingName: '',
            userId: this.$store.state?.getUserProfile?.userId,
            trainingItems: [],
            fetchDataParams: {
                // filter: {
                //     searchTerm: "",
                // },
                paging: {
                    pageNumber: 1,
                    pageSize: 100,
                },
                orderBy: {
                    orderByField: "sortOrder",
                    orderDesc: false,
                },
                //flattenFields: true,
            },
            showError: false,
            errorMsg: "",
            showSucces: false,
            succesMsg: "",

            showEditJourneyNamePopup: false,

            editJourneyShowError: false,
            editJourneyErrorMsg: "",
            editJourneyShowSucces: false,
            editJourneySuccesMsg: "",
            editJourneyNameVal: '',
            //collectionFilter: ["Level", "Nr of Video's", "Coaches & Trainers"],
        };
    },
    // components: {
    // 	JourneyFilterCollection,
    // },
    components: {
        Popup,
        TrainingOverviewItem
        //ContentGridFilterCollection,
        // HeroPage,
    },
    created() {
        this.getTrainingList();
    },
    watch: {
        '$store.state.getUserProfile'() {

            if (this.$store.state?.getUserProfile) {

                this.userId = this.$store.state?.getUserProfile?.userId;

                this.getTrainingList();
            }
        }
    },
    methods: {
        fetchPage() {
            // if (this.isAuthenticated) {
            //     fetchPageBySlug(this.isAuthenticated, "journeys-logged-in").then(
            //         (response) => {
            //             this.pageData = response;
            //         }
            //     );
            // } else {
            fetchPageBySlug(this.isAuthenticated, "my-journey").then(
                (response) => {
                    this.pageData = response;
                }
            );
            // }
        },
        editJourneyChangeInput() {
            this.editJourneyShowError = false;
            this.editJourneyErrorMsg = "";
            this.editJourneyShowSucces = false;
            this.editJourneySuccesMsg = "";
        },
        enableAddJourneyForm() {
            this.showAddJourneyForm = true;
        },
        submitForm() {
            if (this.trainingName && this.trainingName !== '') {

                let params = {
                    "title": this.trainingName,
                    "freeContent": true,
                    "public": false,
                    "published": true,
                    "sortOrder": this.trainingItems.length + 1,
                    //STAGES
                    "trainingStages": [
                        {
                            "title": "Training created by user",
                            "sortOrder": 0,
                            "trainingStageElements": [

                            ]
                        }
                    ]
                }

                createTraining(
                    this.isAuthenticated,
                    params,
                    this.fetchDataParams,
                    true
                ).then((response) => {

                    if (response && response.status === 200) {
                        //Reset stuff
                        this.showAddJourneyForm = false;
                        this.trainingName = '';

                        setTimeout(() => {
                            this.getTrainingList();
                        }, 50);

                        setTimeout(() => {
                            this.hidePopups();
                        }, 100);
                    }

                });

            }
        },
        getTrainingList() {
            if (this.userId) {

                fetchTrainingList(
                    this.isAuthenticated,
                    this.userId,
                    this.fetchDataParams,
                    "?flattenFields=true",
                    true
                ).then((response) => {

                    if (response && response.status === 200) {
                        if (response.data) {
                            this.trainingItems = response.data;
                        }
                    }
                });

            }
        },
        deleteTraining(id) {
            if (id) {
                deleteTrainingSingle(
                    this.isAuthenticated,
                    id,
                    true
                ).then((response) => {
                    if (response && response.status === 204) {
                        //this.getTrainingList();

                        setTimeout(() => {
                            this.getTrainingList();
                        }, 50);
                    }
                });
            }
        },
        changeInput() {
            this.showError = false;
            this.errorMsg = "";
            this.showSucces = false;
            this.succesMsg = "";
        },
        sortOrderUp(item) {
            console.log('sort order up', item);

            // if (item?.sortOrder && item.sortOrder > 0) {
            //     this.updateSortOrder(this.trainingItems, item.id, item.sortOrder - 1);
            // }
        },
        sortOrderDown(item) {
            console.log('sort order down', item);

            // if (item.sortOrder < (this.trainingItems.length + 1)) {
            //     this.updateSortOrder(this.trainingItems, item.id, item.sortOrder + 1);
            // }
        },
        toggleEditJourneyNamePopup() {
            this.showEditJourneyNamePopup = !this.showEditJourneyNamePopup;
        },
        hidePopups() {
            this.showEditJourneyNamePopup = false;
        },
        updateSortOrder(items, idToUpdate, newSortOrder) {
            // Find the index of the item to update
            const itemToUpdateIndex = items.findIndex(item => item.id === idToUpdate);
            const itemToUpdate = items[itemToUpdateIndex];

            if (!itemToUpdate) {
                console.error('Item not found');
                return;
            }

            // Store the original sort order of the item to update
            const originalSortOrder = itemToUpdate.sortOrder;

            // Update the sortOrder of the specified item
            if (newSortOrder <= items.length && newSortOrder > 0) {
                itemToUpdate.sortOrder = newSortOrder;
            }

            // Adjust sortOrders of other items
            items.forEach((item) => {
                if (item.id !== itemToUpdate.id) {
                    if (newSortOrder < originalSortOrder) {
                        // Moving item upwards in the order
                        if (item.sortOrder >= newSortOrder && item.sortOrder < originalSortOrder) {
                            item.sortOrder += 1;
                        }
                    } else if (newSortOrder > originalSortOrder) {
                        // Moving item downwards in the order
                        if (item.sortOrder <= newSortOrder && item.sortOrder > originalSortOrder) {
                            item.sortOrder -= 1;
                        }
                    }
                }
            });

            // Sort the array by the updated sortOrder
            items.sort((a, b) => a.sortOrder - b.sortOrder);

            this.trainingContents.trainingStages[0].trainingStageElements = items;
        },
    },
};
</script>

<style lang="scss" scoped>
// @import styles
@import "./index.scss";
</style>
