<template>
    <div class="training-part part" v-if="data && data !== ''">

        <div class="left-side">
            <div class="tags-wrapper">
                <div class="date">{{ fullDateFormatted(data.created) }}</div>

                <!-- <div>sortOrder: {{ training }}</div> -->

                <div class="amount-of-classes">{{ countClasses(data.trainingStages) }} classes
                </div>
            </div>
            <h2>{{ data.title }}</h2>
        </div>

        <div class="right-side">

            <router-link class="button" :to="'/my-journey/' + data.id">Explore
                training</router-link>

            <!-- <ul class="journey-actions-menu">
                                    <li><button>Move up</button></li>
                                    <li><button>Move down</button></li>
                                    <li><button @click="deleteTraining(training.id)">Delete journey</button></li>
                                </ul> -->

            <ul class="extra-options-wrapper">
                <li>
                    <button @click="toggleOptionsVisibility" :class="{ 'active': optionsVisible }"
                        class="options"></button>
                    <ul class="options-holder">
                        <!-- <li><button class="sort-up" @click="itemSortingUp()">Move up</button></li>
                        <li><button class="sort-down" @click="itemSortingDown()">Move Down</button>
                        </li> -->
                        <li><button class="delete-item" @click="itemDelete()">Delete
                                training</button>
                        </li>
                    </ul>
                </li>
            </ul>

        </div>

    </div>

    <transition name="fade">
        <Popup :elemVisible="showRemoveVideoPopup" :elemClass="'remove-item-popup'" @hidePopupEl="hidePopups()">
            <div class="column content-column remove-item-column">
                <h2 class="column-title">Are you sure?</h2>
                <ul class="confirmation-options">
                    <li><button class="button" type="button" @click="confirmItemDelete">Yes</button>
                    </li>
                    <li><button class="button alt" type="button" @click="hidePopups">No</button>
                    </li>
                </ul>
            </div>
        </Popup>
    </transition>
</template>

<script>

import Popup from "@/components/Popup";

export default {
    data() {
        return {
            optionsVisible: false,
            showRemoveVideoPopup: false,
        };
    },
    props: ["data"],
    created() { },
    inject: ["isAuthenticated"],
    components: {
        Popup
    },
    emits: ["sortUp", "sortDown", "deleteItem"],
    mounted() {

    },
    methods: {
        itemSortingUp() {
            this.$emit("sortUp");
        },
        itemSortingDown() {
            this.$emit("sortDown");
        },
        itemDelete() {
            this.showRemoveVideoPopup = !this.showRemoveVideoPopup;
            this.optionsVisible = false;
        },
        confirmItemDelete() {
            this.$emit("deleteItem");
        },
        toggleOptionsVisibility() {
            this.optionsVisible = !this.optionsVisible;

        },
        fullDateFormatted(date) {
            let initialDate = date;

            //Timezone offset
            //let hrs = -(new Date().getTimezoneOffset() / 60);

            // const months = [
            //     "Jan.",
            //     "Feb.",
            //     "Mrt.",
            //     "Apr.",
            //     "Mei.",
            //     "Jun.",
            //     "Jul.",
            //     "Aug.",
            //     "Sept.",
            //     "Okt.",
            //     "Nov.",
            //     "Dec.",
            // ];

            let d = new Date(initialDate);

            const addZero = (i) => {
                if (i < 10) {
                    i = "0" + i;
                }
                return i;
            };

            const monthIndex = addZero(d.getMonth() + 1);
            //const monthName = months[monthIndex];
            const year = d.getFullYear();
            const day = addZero(d.getDate());
            // const hours = addZero(d.getHours() + hrs);
            // const minutes = addZero(d.getMinutes());

            return (
                day + "-" + monthIndex + "-" + year
            );
        },
        countClasses(field) {
            if (field && field.length > 0) {

                let count = 0;

                field.forEach((stage) => {
                    if (stage?.trainingStageElements && stage.trainingStageElements.length > 0) {
                        stage.trainingStageElements.forEach(() => {
                            count++;
                        });
                    }
                });

                return count;
            }

            return 0;
        },
        hidePopups() {
            this.showRemoveVideoPopup = false;
        },

    },
};
</script>

<style lang="scss" scoped>
// @import styles
@import "./index.scss";
</style>
